import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
import 'formdata-polyfill'
import storage from 'local-storage-fallback'
import 'react-app-polyfill/stable'
import { createRoot } from 'react-dom/client'

import './index.css'
import BundleSelector from './BundleSelector'
// import * as serviceWorker from './serviceWorker'
import Bugsnag from './setUp/Bugsnag'

if (!('localStorage' in window)) {
  ;(window as any).localStorage = storage
}

const root = createRoot(document.getElementById('root')!)

root.render(
  <Bugsnag>
    <BundleSelector />
  </Bugsnag>,
)

// TODO: Investigate if we can re-enable service worker
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
