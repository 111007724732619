import React, { useState, useEffect } from 'react'

import logo from 'assets/images/logo-agile-a.png'

import styles from './Bootstrapping.module.scss'

const LoadingBundle: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [numDots, setNumDots] = useState(3)

  useEffect(() => {
    let shouldSetState = true

    setTimeout(() => {
      if (shouldSetState) {
        if (numDots === 4) {
          setNumDots(1)
        } else {
          setNumDots((count) => count + 1)
        }
      }
    }, 500)

    return () => {
      shouldSetState = false
    }
  }, [numDots])

  const text = 'Just getting things ready'
  let dots = ''
  for (let i = 1; i < numDots; i++) {
    dots = dots + '.'
  }
  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <img src={logo} alt="Agile Underwriting" />
      </div>
      <div className={styles.wrapper}>
        <p>{text + dots}</p>
      </div>
    </div>
  )
}

export default LoadingBundle
