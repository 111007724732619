import { Event } from '@bugsnag/core/types'

import WhoAmI from 'models/WhoAmI'

/**
 * Using var, because we need to set this in the setCurrentBugsnagUsercallback
 * scope
 */
let currentWhoAmI: WhoAmI | undefined

/**
 * Call this on Authentication so we can track who was logged in when any errors
 * occur
 *
 * @param whoAmI WhoAmI
 */
export const setCurrentBugsnagUser = (whoAmI: WhoAmI | undefined) => {
  currentWhoAmI = whoAmI
}

/**
 * Attaches information about the current user and partner/customer to the
 * bugsnag report
 */
export const onError = (event: Event) => {
  if (currentWhoAmI) {
    const user = currentWhoAmI.user
    event.setUser(user.id, user.name, user.email)
    event.addMetadata('additional', {
      partnerId: currentWhoAmI.partnerId,
      customerId: currentWhoAmI.customerId,
    })
  }
}
